import React from "react"
import Image from "./image"
// import styled from "styled-components"
// import { StyledLink as Link } from "../link"
import { CardContainer, Card, CardContent, Logo } from "./card"
import { useTranslation } from "react-i18next"

export default ({ ...props }) => {
  const { t } = useTranslation()
  return (
    <CardContainer {...props}>
      <Card>
        <Image
          filename="logo-astar.png"
        />
        <CardContent>
          {t(
            "セキュリティ検査",
            { ns: "product" }
          )}
        </CardContent>
      </Card>
      <Card>
        <Image
          filename="logo-shiden.png"
          style = {{
            margin: "20px",
          }}
        />
        <CardContent>
          {t(
            "分散技術のコンサルティング",
            { ns: "product" }
          )}
        </CardContent>
      </Card>
    </CardContainer>
  )
}
